<template>
  <v-container fluid pb-5 mb-4>
    <v-breadcrumbs :items="breadcrumbs" divider=">" hidden></v-breadcrumbs>

    <div v-if="loading" class="primary--text mb-4">
      <v-progress-circular
        indeterminate
        color="primary"
        class="mr-2"
      ></v-progress-circular>

      Cargando los datos, por favor espere...
    </div>

    <v-card v-else outlined>
      <v-card-title class="headline">Detalles</v-card-title>

      <v-card-text>
        <v-simple-table class="mb-2">
          <template v-slot:default>
            <thead>
              <tr>
                <th>Método de Pago</th>
                <th>Importe Total</th>
                <th>Tipo Documentol</th>
                <th>N Documento</th>
                <th>Categoría IVA</th>
                <th>Razón Social/Nombre y Apellido</th>
                <th>E-Mail</th>
                <th>Teléfono</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ venta.metodo_pago_descripcion }}</td>
                <td>{{ venta.importe_total_fmt }}</td>
                <td>{{ venta.facturacion_doc_tipo_descripcion }}</td>
                <td>{{ venta.facturacion_doc_numero }}</td>
                <td>{{ venta.facturacion_categoria_iva_descripcion }}</td>
                <td>{{ venta.facturacion_razon_social }}</td>
                <td>{{ venta.facturacion_email }}</td>
                <td>{{ venta.facturacion_telefono }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <v-card-subtitle class="headline">Cupones</v-card-subtitle>

        <v-data-table
          v-model="selected"
          :headers="headersCupones"
          :items="venta.items"
          item-key="id"
          show-select
          class="elevation-1"
        >
          <template v-slot:item.action="{ item }">
            <v-icon
              small
              class="mr-2"
              title="Imprimir Cupones"
              v-if="item.estado_codigo == 'activo' && venta.estado_codigo == 'pagado'"
              @click="imprimirCupon(item)"
            >
                mdi-printer
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>

      <v-card-actions>
        <v-btn
          text
          :color="imprimirBtnColor"
          @click="imprimirCupones"
        >
          Imprimir Cupones
        </v-btn>

        <v-spacer></v-spacer>

        <v-btn
          plain
          :to="{ name: 'historial' }"
        >
          Volver al historial
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>

export default {
  data: () => ({
    loading: true,
    headersCupones: [
      { text: 'Detalle', value: 'detalle', align: 'justify-center', sortable: false },
      { text: 'Estado', value: 'estado_descripcion', align: 'justify-center', sortable: false },
      { text: 'Precio Unitario', value: 'importe_fmt', align: 'justify-right', sortable: false },
      { text: 'Acciones', value: 'action', align: 'justify-center', sortable: false },
    ],
    selected: [],
    venta: {},
    breadcrumbs: [
      {
        text: 'Compras',
        disabled: false,
        exact: true,
        to: { name: 'historial' }
      },
    ]
  }),

  computed: {
    imprimirBtnColor () {
      if ((this.selected.length != 0) && (this.venta.estado_codigo == 'pagado') && (this.venta.has_cupones_activos != 0)) {
        return 'green'
      }

      return 'grey darken-1'
    }
  },

  methods: {
    viewDetails: function (id) {
      this.loading = true

      this.$http.get('cuenta/historial/details/'+id)
        .then((response) => {
          this.venta = response.data
          this.selected = []
          this.breadcrumbs[1].text = 'Compra #'+this.venta.id
        })
        .catch(function (error) {
          switch (error.response.status) {
            case 401:
              break;
            default:
              this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud', 'error')
            }
        })
        .then(() => {
          this.loading = false
        })
    },

    imprimirCupon:function (item) {
      if (Array.isArray(item) && item.length == 1 && item[0].estado_codigo != 'activo') {
        this.$eventHub.$emit('snackbar-message', 'No hay ningun cupón activo para imprimir', 'error')
        return;
      }

      var qrCodePrintWindow = window.open(false, "Cupón")
      let fecha_actual = new Date()

      let day = fecha_actual.getDate()
      let month = ('0' + (fecha_actual.getMonth() + 1)).slice(-2)
      let year = fecha_actual.getFullYear()

      fecha_actual = `${day}/${month}/${year}`

      qrCodePrintWindow.document.write('<div style="text-align:center;">')

      if (Array.isArray(item)) {
        item.forEach((cupon) => {
          if (cupon.estado_codigo == 'activo') {
            qrCodePrintWindow.document.write('<img src="'+cupon.codigoQR+'" alt="">')
            qrCodePrintWindow.document.write('<p><strong>Código: </strong>' + cupon.codigo + '</p>')
            qrCodePrintWindow.document.write('<p><strong>Detalle: </strong>' + cupon.detalle + '</p>')
            qrCodePrintWindow.document.write('<p><strong>Estado: </strong>' + cupon.estado_descripcion + '</p>')
            qrCodePrintWindow.document.write('<p><strong>Fecha: </strong>' + fecha_actual + '</p>')

            qrCodePrintWindow.document.write('<div style="border-bottom-style: dotted;"></div>')
          }
        })
      } else {
        qrCodePrintWindow.document.write('<img src="'+item.codigoQR+'" alt="">')
        qrCodePrintWindow.document.write('<p><strong>Código: </strong>' + item.codigo + '</p>')
        qrCodePrintWindow.document.write('<p><strong>Detalle: </strong>' + item.detalle + '</p>')
        qrCodePrintWindow.document.write('<p><strong>Estado: </strong>' + item.estado_descripcion + '</p>')
        qrCodePrintWindow.document.write('<p><strong>Fecha: </strong>' + fecha_actual + '</p>')

        qrCodePrintWindow.document.write('<div style="border-bottom-style: dotted;"></div>')
      }

      qrCodePrintWindow.document.write('</div>')

      qrCodePrintWindow.onafterprint = qrCodePrintWindow.close

      setTimeout(function() {
        qrCodePrintWindow.print()
        qrCodePrintWindow.close()
      }, 100);
    },

    imprimirCupones: function () {
      if (this.selected.length == 0) {
        this.$eventHub.$emit('snackbar-message', 'Debe seleccionar al menos un cupón', 'error')
        return;
      }

      if (this.venta.estado_codigo == 'esperando') {
        this.$eventHub.$emit('snackbar-message', 'La compra todavía no fue pagada', 'error')
        return;
      }

      if (this.venta.has_cupones_activos == 0) {
        this.$eventHub.$emit('snackbar-message', 'No hay ningun cupón activo para imprimir', 'error')
        return;
      }

      this.imprimirCupon(this.selected)
    }
  },

  mounted: function () {
    if (!this.$route.params.id) {
      this.$router.push({ name: 'historial'})
    }

    this.breadcrumbs.push({
      text: 'Compra #' + this.$route.params.id,
      disabled: true,
      exact: true,
      name: { name: 'historial-detalle', params: { id: this.$route.params.id }}
    })

    this.viewDetails(this.$route.params.id)
  }
}
</script>
